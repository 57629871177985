import { useState, useEffect } from 'react';

const DEFAULT_BREAKPOINTS = {
  isSmallMobile: false,
  isMobile: false,
  fromTablet: false,
  fromDesktop: false,
  fromLargeDesktop: false,
};

export const calculateBreakpoints = (width: number) => {
  if (width < 768) {
    return {
      ...DEFAULT_BREAKPOINTS,
      isSmallMobile: true,
      isMobile: true,
    };
  }
  if (width < 1024) {
    return {
      ...DEFAULT_BREAKPOINTS,
      isMobile: true,
    };
  }
  if (width >= 1024 && width < 1440) {
    return {
      ...DEFAULT_BREAKPOINTS,
      fromTablet: true,
    };
  }
  if (width >= 1440 && width < 1920) {
    return {
      ...DEFAULT_BREAKPOINTS,
      fromTablet: true,
      fromDesktop: true,
    };
  }

  return {
    ...DEFAULT_BREAKPOINTS,
    fromTablet: true,
    fromDesktop: true,
    fromLargeDesktop: true,
  };
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [breakpoints, setBreakpoints] = useState(DEFAULT_BREAKPOINTS);
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { width, height } = getWindowDimensions();
    setWindowDimensions({ width, height });
    setBreakpoints(calculateBreakpoints(width));
    function handleResize() {
      const { width, height } = getWindowDimensions();
      setWindowDimensions({ width, height });
      setBreakpoints(calculateBreakpoints(width));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...breakpoints, ...windowDimensions };
}
